<div id="wrapper" *ngIf="filteredPolicies$ | async as policyDefinitions">
<!--    entry fields for new policy-->
  <div class="container">
    <mat-form-field appearance="outline" class="search-form-field" color="accent">
      <mat-label>Search policies</mat-label>
      <input (input)="onSearch()" [(ngModel)]="searchText" matInput>
      <mat-icon matPrefix>search</mat-icon>
      <button (click)="searchText=''; onSearch()" *ngIf="searchText" mat-icon-button matSuffix>
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-paginator [length]="policyDefinitions?.length" hidePageSize="true" pageSize="10"></mat-paginator>

    <button (click)="onCreate()" color="accent" mat-stroked-button>
      <mat-icon>add_circle_outline</mat-icon>
      Create policy
    </button>

  </div>

  <div fxLayout="column" fxLayoutAlign="space-around stretch">
    <mat-card *ngFor="let policyDef of policyDefinitions" class="policy-card">
      <mat-card-header>
        <mat-icon mat-card-avatar>policy</mat-icon>
        <mat-card-title><span class="code">{{policyDef.id}}</span></mat-card-title>
      </mat-card-header>

      <mat-card-content>

        <mat-list dense>
          <mat-list-item>
            <mat-icon matListItemIcon>trip_origin</mat-icon>
            <div matListItemTitle>{{policyDef.policy.assigner || 'n/a'}}</div>
            <div matListItemLine>Assigner</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>adjust</mat-icon>
            <div matListItemTitle>{{policyDef.policy.assignee || 'n/a'}}</div>
            <div matListItemLine>Assignee</div>
          </mat-list-item>
        </mat-list>

        <!-- Permissions-->
        <policy-rules [rules]="policyDef.policy.permissions" [title]="'Permissions'"></policy-rules>

        <!-- Obligations-->
        <policy-rules [rules]="policyDef.policy.obligations" [title]="'Obligations'"></policy-rules>

        <!-- Prohibitions-->
        <policy-rules [rules]="policyDef.policy.prohibitions" [title]="'Prohibitions'"></policy-rules>

      </mat-card-content>
      <button (click)="delete(policyDef)" mat-stroked-button color="warn" matSuffix>
        <mat-icon>delete</mat-icon>
        Delete
      </button>
      <mat-card-actions>

      </mat-card-actions>
    </mat-card>
  </div>
</div>
