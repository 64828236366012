<div *ngIf="filteredAssets$ | async as assets" id="wrapper">
  <div class="container">
    <mat-form-field appearance="fill" appearance="outline" class="search-form-field" color="accent">
      <!-- <mat-label>Search assets</mat-label> -->
      <input (input)="onSearch()" [(ngModel)]="searchText" matInput>
      <mat-icon matPrefix>search</mat-icon>
      <button (click)="searchText=''; onSearch()" *ngIf="searchText" mat-icon-button matSuffix>
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-paginator [length]="assets.length" hidePageSize="true" pageSize="contractDefinitions.length">
    </mat-paginator>

    <button (click)="onCreate()" color="accent" mat-stroked-button>
      <mat-icon>add_circle_outline</mat-icon>
      Create asset
    </button>
  </div>

  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <mat-card *ngFor="let asset of assets" class="asset-card">
      <mat-card-header>
        <mat-icon mat-card-avatar>id</mat-icon>
        <mat-card-title>{{asset.id}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list dense>
          <mat-list-item>
            <mat-icon matListItemIcon>info</mat-icon>
            <div class="asset-property" matListItemTitle>Name</div>
            <div matListItemLine>{{asset.properties.optionalValue('edc','name')}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>category</mat-icon>
            <div class="asset-property" matListItemTitle>Content Type</div>
            <div matListItemLine>{{asset.properties.optionalValue('edc','contenttype')}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>file_copy</mat-icon>
            <div class="asset-property" matListItemTitle>Version</div>
            <div matListItemLine>{{asset.properties.optionalValue('edc','version')}}</div>
          </mat-list-item>
        </mat-list>
      </mat-card-content>

      <mat-divider inset></mat-divider>
      <mat-card-actions class="card-actions">
        <button (click)="onDelete(asset)" [disabled]="isBusy()" color="warn" mat-stroked-button>
          <mat-icon>delete_sweep</mat-icon> Delete
        </button>
      </mat-card-actions>
      <mat-card-footer>
        <mat-progress-bar *ngIf="isBusy()" color="accent" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
