<div *ngIf="contracts$ | async as contracts" id="wrapper">
  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <mat-card *ngFor="let contract of contracts" class="contract-card">
      <mat-card-header>
        <mat-icon mat-card-avatar>attachment</mat-icon>
        <mat-card-title>{{contract.id}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <!-- asset -->
          <mat-list-item>
            <mat-icon mat-list-icon>category</mat-icon>
            <div class="contract-property-header" matListItemTitle>Asset</div>
            <div class="contract-property-value" matListItemLine>{{contract.assetId}}</div>
          </mat-list-item>

          <!-- provider -->
          <mat-list-item>
            <mat-icon mat-list-icon>person</mat-icon>
            <div class="contract-property-header" matListItemTitle>Provider</div>
            <div class="contract-property-value" matListItemLine>{{contract.providerId}}</div>
          </mat-list-item>

          <!-- sign date -->
          <mat-list-item>
            <mat-icon mat-list-icon>draw</mat-icon>
            <div class="contract-property-header" matListItemTitle>Signing date</div>
            <div class="contract-property-value" matListItemLine>{{asDate(contract.mandatoryValue('edc', 'contractSigningDate'))}}</div>
          </mat-list-item>

          <mat-divider inset></mat-divider>
          <mat-card-actions class="card-actions">
            <button (click)="onTransferClicked(contract)" [disabled]="isTransferInProgress(contract.id!)" color="accent"
                    mat-stroked-button>
              <mat-icon>downloading</mat-icon>
              Transfer
            </button>
          </mat-card-actions>
          <mat-card-footer class="contract-card-footer">
            <mat-progress-bar *ngIf="isTransferInProgress(contract.id!)" color="accent" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>

        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
