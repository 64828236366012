<mat-dialog-content>

  <!--  ID -->
  <mat-form-field class="form-field-stretch" color="accent" id="form-field-id">
    <mat-label>ID</mat-label>
    <input [(ngModel)]="policyDefinition.id" matInput required>
  </mat-form-field>

  <!--  Assigner / Assignee-->
  <div>
    <mat-form-field class="form-field form-field-half" id="form-field-asignee">
      <mat-label>Assignee</mat-label>
      <input [(ngModel)]="policy.assignee" matInput>
    </mat-form-field>

    <mat-form-field class="form-field form-field-half" id="form-field-assigner">
      <mat-label>Assigner</mat-label>
      <input [(ngModel)]="policy.assigner" matInput>
    </mat-form-field>
  </div>

  <!--  Permissions -->
  <mat-form-field class="form-field-stretch" color="accent" id="form-field-permissions">
    <mat-label>Permissions (JSON)</mat-label>
    <input [(ngModel)]="permissionsJson" matInput>
  </mat-form-field>

  <!--  Prohibitions -->
  <mat-form-field class="form-field-stretch" color="accent" id="form-field-prohibitions">
    <mat-label>Prohibitions (JSON)</mat-label>
    <input [(ngModel)]="prohibitionsJson" matInput>
  </mat-form-field>

  <!--  Obligations -->
  <mat-form-field class="form-field-stretch" color="accent" id="form-field-obligations">
    <mat-label>Obligations (JSON)</mat-label>
    <input [(ngModel)]="obligationsJson" matInput>
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="onSave()" *ngIf="editMode" color="accent" mat-stroked-button >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button (click)="onSave()" *ngIf="!editMode" color="accent" mat-stroked-button>
    <mat-icon>add_circle_outline</mat-icon>
    Create
  </button>

  <button color="accent" mat-dialog-close="true" mat-stroked-button>
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</mat-dialog-actions>
