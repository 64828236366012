<div *ngIf="filteredContractDefinitions$ | async as contractDefinitions" id="wrapper">
    <div class="container">
        <mat-form-field appearance="outline" class="search-form-field" color="accent">
            <mat-label>Search definitions</mat-label>
            <input (input)="onSearch()" [(ngModel)]="searchText" matInput>
            <mat-icon matPrefix>search</mat-icon>
            <button (click)="searchText=''; onSearch()" *ngIf="searchText" mat-icon-button matSuffix>
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-paginator [length]="contractDefinitions.length" hidePageSize="true" pageSize="contractDefinitions.length">
        </mat-paginator>

        <button (click)="onCreate()" color="accent" mat-stroked-button>
          <mat-icon>add_circle_outline</mat-icon>
          Create contract definition
        </button>

    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start start">

        <mat-card *ngFor="let contractDefinition of contractDefinitions" class="contract-definition-card">
            <mat-card-header>
                <mat-icon mat-card-avatar>policy</mat-icon>
                <mat-card-title>{{contractDefinition.id}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-list dense>
                    <mat-list-item>
                        <mat-icon matListItemIcon>policy</mat-icon>
                        <div class="policy-name" matListItemTitle>Access policy</div>
                        <div matListItemLine>{{contractDefinition.accessPolicyId}}</div>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListItemIcon>policy</mat-icon>
                        <div class="policy-name" matListItemTitle>Contract policy</div>
                        <div matListItemLine>{{contractDefinition.contractPolicyId}}</div>
                    </mat-list-item>
                </mat-list>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Selectors
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list dense>
                        <mat-list-item *ngFor="let criterion of contractDefinition.assetsSelector">
                            <mat-icon matListItemIcon>check</mat-icon>
                            <!-- mandatoryValue method needs to be used because of this bug: https://github.com/Think-iT-Labs/edc-connector-client/issues/94 -->
                            <div matListItemLine>{{criterion.mandatoryValue('edc', 'operandLeft')}} {{criterion.mandatoryValue('edc', 'operator')}} {{criterion.mandatoryValue('edc', 'operandRight')}}</div>
                        </mat-list-item>
                    </mat-list>
                </mat-expansion-panel>

            </mat-card-content>

            <mat-divider inset></mat-divider>
            <mat-card-actions class="card-actions">
                <button (click)="onDelete(contractDefinition)" color="warn" mat-stroked-button>
                    <mat-icon>delete_sweep</mat-icon> Delete
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
