<div *ngIf="filteredContractOffers$ | async as filteredContractOffers" id="wrapper">
  <div class="container">
    <mat-form-field class="search-form-field" color="accent">
      <mat-label>Search catalog</mat-label>
      <input (input)="onSearch()" [(ngModel)]="searchText" matInput>
      <mat-icon matPrefix>search</mat-icon>
      <button (click)="searchText=''; onSearch()" *ngIf="searchText" mat-icon-button matSuffix>
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-paginator [length]="(filteredContractOffers)?.length" hidePageSize="true"
                   pageSize="(filteredContractOffers)?.length">
    </mat-paginator>

        <!-- <button mat-button (click)="onSearch()">Search</button> -->
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start start">

        <mat-card *ngFor="let contractOffer of filteredContractOffers" class="asset-card">
            <mat-card-header>
                <mat-icon mat-card-avatar>sim_card</mat-icon>
                <mat-card-title>{{contractOffer.properties.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-list dense>
                    <mat-list-item>
                        <mat-icon matListItemIcon>category</mat-icon>
                      <div class="asset-property-name" matListItemTitle>Type</div>
                        <div matListItemLine>{{contractOffer.properties.type}}</div>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListItemIcon>key</mat-icon>
                      <div class="asset-property-name" matListItemTitle>Id</div>
                        <div matListItemLine>{{contractOffer.assetId}}</div>
                    </mat-list-item>
                    <mat-list-item *ngIf="contractOffer.originator">
                        <mat-icon matListItemIcon>link</mat-icon>
                      <div class="asset-property-name" matListItemTitle>Originator</div>
                        <div matListItemLine>{{contractOffer.originator}}</div>
                    </mat-list-item>
                </mat-list>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Additional properties
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list dense>
                        <mat-list-item>
                            <mat-icon matListItemIcon>numbers</mat-icon>
                          <div class="asset-property-name" matListItemTitle>Version</div>
                            <div matListItemLine>{{contractOffer.properties.version}}</div>
                        </mat-list-item>
                        <mat-list-item *ngIf="contractOffer.properties.contentType">
                            <mat-icon matListItemIcon>content_paste</mat-icon>
                          <div class="asset-property-name" matListItemTitle>Content-Type</div>
                            <div matListItemLine>{{contractOffer.properties.contentType}}</div>
                        </mat-list-item>
                      <mat-list-item *ngFor="let additionalPropertyKey of contractOffer.properties.additionalPropertyKeys">
                        <mat-icon matListItemIcon>list</mat-icon>
                        <div *ngIf="additionalPropertyKey.replace('asset:prop:', '') as name"
                             class="asset-property-name" matListItemTitle>
                          {{name.charAt(0).toUpperCase() + name.slice(1)}}
                        </div>
                        <div matListItemLine title="{{contractOffer.properties.properties[additionalPropertyKey]}}">
                          {{contractOffer.properties.properties[additionalPropertyKey]}}</div>
                      </mat-list-item>
                    </mat-list>
                </mat-expansion-panel>

            </mat-card-content>

            <mat-divider inset></mat-divider>
            <mat-card-actions class="card-actions">
              <button (click)="onNegotiateClicked(contractOffer)"
                      [disabled]="isBusy(contractOffer) || isNegotiated(contractOffer)"
                      color="accent" mat-stroked-button>
                <mat-icon>drive_file_rename_outline</mat-icon>
                Negotiate
              </button>
            </mat-card-actions>
            <mat-card-footer>
              <mat-progress-bar *ngIf="isBusy(contractOffer)" color="accent" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </div>
</div>
